<template>
    <div>
        <v-alert
            type="error"
            class="mb-5"
            :value="errorMessage">
            {{ errorMessage }}
        </v-alert>
        <div class="mb-4">
            <template v-if="googleIdentityComplete">
                <h2>Google Business Profile Setup Complete</h2>
                <p>
                    You have successfully configured a Google Business Profile!
                    If you'd like to change the profile just click "change" below
                    and select a new one:
                </p>
            </template>
            <template v-else-if="initialPlaceId">
                <h2>Verify Google Business Profile</h2>
                <p>
                    In order to utilize Google products, BuyerBridge needs to know
                    which Google Business Profile to associate with this account.
                    <template v-if="dealerInAutoVertical">
                        For automotive accounts, this data will be used to determine
                        which profile to associate vehicle listings with.
                    </template>
                    Please confirm that the business profile used when this account
                    was created is accurate:
                </p>
            </template>
            <template v-else>
                <h2>Select Google Business Profile</h2>
                <p>
                    In order to utilize Google products, BuyerBridge needs to know
                    which Google Business Profile to associate with this account.
                    <template v-if="dealerInAutoVertical">
                        For automotive accounts, this data will be used to determine
                        which profile to associate vehicle listings with.
                    </template>
                    Please use the form below to select a profile:
                </p>
            </template>
        </div>

        <div class="mb-5">
            <template v-if="placeId">
                <h4 class="selected-place-text">
                    Selected Profile:
                </h4>
                <div class="selected-place">
                    <google-place
                        :value="placeId" />
                    <styled-button
                        small
                        outline
                        @click="handlePlaceChange">
                        Change
                    </styled-button>
                </div>
            </template>
            <template v-else>
                <place-search
                    label="Google Business Profile"
                    placeholder="Search for Google Business Profile..."
                    append-place-photo
                    return-id
                    @input="handlePlaceSelection"
                    @error="handlePlaceError" />
            </template>
        </div>


        <div class="layout align-center">
            <back-button @click="goToPreviousStep" />
            <styled-button
                class="ml-auto"
                :disabled="!placeId"
                :loading="loadingIdentityUpdate"
                @click="handleContinue">
                Continue
            </styled-button>
        </div>
    </div>
</template>

<script>
import PlaceSearch from '@/components/globals/PlaceSearch';
import GooglePlace from '@/components/globals/GooglePlace';
import StyledButton from '@/components/globals/StyledButton';
import BackButton from '@/components/globals/BackButton';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
    FEATURE_GOOGLE_IDENTITY,
    PLATFORM_ID_GOOGLE
} from '@/helpers/globals';

export default {
    name: 'GoogleBusinessProfile',
    components: {
        PlaceSearch,
        GooglePlace,
        StyledButton,
        BackButton
    },
    data() {
        return {
            loadingIdentityUpdate: false,
            placeId: null,
            errorMessage: '',
        };
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters([
            'dealerInAutoVertical',
            'dealerFeaturesComplete'
        ]),
        googleIdentityComplete() {
            return this.dealerFeaturesComplete.includes(FEATURE_GOOGLE_IDENTITY);
        },
        initialPlaceId() {
            return this.dealer.configuration?.initial_place_id;
        },
        storedGoogleIdentity() {
            return this.dealer.identities?.data?.find(identity => (
                identity.platform_id === PLATFORM_ID_GOOGLE
            )) ?? null;
        }
    },
    created() {
        if (this.googleIdentityComplete) {
            this.placeId = this.storedGoogleIdentity.external_identity_id;
        } else if (this.initialPlaceId) {
            this.placeId = this.initialPlaceId;
        }
    },
    methods: {
        ...mapActions([
            'goToPreviousStep',
            'goToNextStep'
        ]),
        async handleContinue() {
            try {
                this.errorMessage = '';
                this.loadingIdentityUpdate = true;

                // Swap identities if the user has changed their selection
                if (
                    this.storedGoogleIdentity
                    && this.storedGoogleIdentity?.external_identity_id !== this.placeId
                ) {
                    await this.createNewIdentity();
                    await this.deleteExistingIdentity();
                }
                // Create a new identity if the account doesn't already have one
                else if (!this.storedGoogleIdentity) {
                    await this.createNewIdentity();
                }

                this.goToNextStep();
            } catch(error) {
                this.errorMessage = error;
            } finally {
                this.loadingIdentityUpdate = false;
            }
        },
        handlePlaceSelection(placeId) {
            this.placeId = placeId;
        },
        handlePlaceError(error) {
            console.error('Error retrieving Google Place', error?.response || error);
                this.errorMessage = 'Error retrieving Google Place. Please try again or contact support.';
        },
        handlePlaceChange() {
            this.placeId = null;
        },
        async createNewIdentity() {
            try {
                await this.$apiRepository.createIdentity(
                    this.dealer.id,
                    PLATFORM_ID_GOOGLE,
                    this.placeId
                );
            } catch (error) {
                console.error('Error creating Google identity', error?.response || error);
                throw new Error('Error creating Google identity. Please try again or contact support.');
            }
        },
        async deleteExistingIdentity() {
            try {
                await this.$apiRepository.deleteIdentity(
                    this.storedGoogleIdentity.id
                );
            } catch (error) {
                console.error('Error deleting Google identity', error?.response || error);
                throw new Error('Error deleting Google identity. Please try again or contact support.');
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.selected-place-text {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
}

.selected-place {
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px rgba($black, 0.2);
    padding: 15px;
    button {
        margin-left: auto;
        margin-right: 24px;
        text-transform: uppercase;
    }
}
</style>