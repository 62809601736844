<template>
    <div class="google-place">
        <div
            v-if="error"
            class="google-place-error">
            {{ error }}
        </div>
        <styled-loader
            v-else-if="loadingPlace"
            size="15" />
        <template v-else>
            <img :src="place.photo">
            <div
                v-if="place"
                class="google-place-details">
                <h3>{{ place.name }}</h3>
                <p class="google-place-address">
                    {{ place.address.formatted }}
                </p>
                <p class="google-place-phone">
                    {{ place.phone }}
                </p>
            </div>
        </template>
    </div>
</template>

<script>
import StyledLoader from '@/components/globals/StyledLoader';
import formatGooglePlace from '@/helpers/formatGooglePlace';

export default {
    components: {
        StyledLoader
    },
    props: {
        value: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            error: '',
            loadingPlace: false,
            place: null,
        };
    },
    watch: {
        placeId() {
            this.getPlace();
        }
    },
    created() {
        this.getPlace();
    },
    methods: {
        async getPlace() {
            try {
                this.loadingPlace = true;
                this.error = '';

                const response = await this.$apiRepository.getGooglePlace(this.value);

                if (!response.data.status == 'OK') {
                    throw 'Google returned bad status code';
                }

                this.place = formatGooglePlace(response.data.result);
            } catch(error) {
                console.error('Error retrieving place',  error.response ?? error);
                this.error = 'There was an error retrieving this Google Place.  Please try again or contact support.';
            } finally {
                this.loadingPlace = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.google-place {
    display: flex;
    align-items: center;
    img {
        width: auto;
        height: 70px;
        object-fit: cover;
    }
}

.google-place-details {
    margin-left: 15px;
    h3 {
        font-weight: 600;
    }
    p {
        margin-bottom: 0;
        color: $gray;
    }
}

.google-place-error {
    color: $error-500;
}
</style>
